import axios from "axios";
import config from "../utils/config";
import { Auth } from "aws-amplify";

export const highlightsAndQuestionsFromTranscript = async (
  therapistId,
  transcript
) => {
  // const baseURL = config.API_URL;
  // const path = "/realtime/ai_highlights";

  const body = {
    therapist_id: therapistId,
    transcript: transcript,
  };

  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.post(
    `https://v2axdvk3meny5lmf5gg2vgx2wu0izets.lambda-url.us-west-2.on.aws/`,
    body,
    {
      headers: {
        Authorization: idToken,
      },
    }
  );

  return data;
};

export const getPreviousSessionHighlights = async (therapistId) => {
  const baseURL = config.API_URL;
  const path = "/realtime/previous_highlights";
  const params = "?therapist_id=" + therapistId;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });

  return data;
};

export const createAiReportWithTranscript = async (
  therapistId,
  transcript,
  note_format,
  temp_real_time_session_id
) => {
  const baseURL = config.API_URL;
  const path = "/realtime/ai_soap";

  const body = {
    therapist_id: therapistId,
    transcript: transcript,
    note_format: note_format,
    realtime_session_id: temp_real_time_session_id,
  };

  // const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.post(
    `https://zbosmwli5xuqbyydd25jw5vsh40azklv.lambda-url.us-west-2.on.aws/`,
    body
  );

  // const data = await axios.post(`${baseURL + path}`, body, {
  //   headers: {
  //     Authorization: idToken,
  //   },
  // });

  return data;
};

export const createRealtimeSession = async (therapistId, therapy_id) => {
  const baseURL = config.API_URL;
  const path = "/realtime/session";

  console.log("therapy_id when calling api -> ", therapy_id);

  const body = {
    therapist_id: therapistId,
    therapy_id: therapy_id,
  };

  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.post(`${baseURL + path}`, body, {
    headers: {
      Authorization: idToken,
    },
  });
  return data;
};

export const updateRealtimeSessionWithNoteId = async (
  therapistId,
  realtimeSessionId,
  noteId
) => {
  const baseURL = config.API_URL;
  const path = "/realtime/session";

  const body = {
    therapist_id: therapistId,
    real_time_session_id: realtimeSessionId,
    note_id: noteId,
  };

  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.put(`${baseURL + path}`, body, {
    headers: {
      Authorization: idToken,
    },
  });
  return data;
};

export const getRealtimeSessionInfoById = async (
  therapistId,
  realtimeSessionId
) => {
  const baseURL = config.API_URL;
  const path = "/realtime/session";
  const params =
    "?therapist_id=" +
    therapistId +
    "&real_time_session_id=" +
    realtimeSessionId;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });
  return data;
};

export const getRealtimeSessionDetailsAPI = async (
  therapistId,
  realtimeSessionId
) => {
  const baseURL = config.API_URL;
  const path = "/realtime/session_details";
  const params =
    "?therapist_id=" +
    therapistId +
    "&real_time_session_id=" +
    realtimeSessionId;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });
  return data;
};

export const determineRoleFromTranscript = async (therapistId, annotations) => {
  const baseURL = config.API_URL;
  const path = "/realtime/ai_transcript_role";

  const body = {
    therapist_id: therapistId,
    annotations: annotations,
  };

  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.post(`${baseURL + path}`, body, {
    headers: {
      Authorization: idToken,
    },
  });
  return data;
};

export const generateClientSentimentScoreAPI = async (
  transcript,
  end_time,
  start_time
) => {
  const baseURL = config.API_URL;
  const path = "/realtime/sentiments";

  const body = {
    transcript: transcript,
    end_time: end_time,
    start_time: start_time,
  };

  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.post(`${baseURL + path}`, body, {
    headers: {
      Authorization: idToken,
    },
  });
  return data;
};

export const saveSentimentScoresAPI = async (sentiment_scores) => {
  const baseURL = config.API_URL;
  const path = "/realtime/save_sentiments";

  const body = {
    sentiment_scores,
  };

  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.post(`${baseURL + path}`, body, {
    headers: {
      Authorization: idToken,
    },
  });
  return data;
};

export const saveRealtimeTranscript = async (
  therapistId,
  therapyId,
  realtimeId,
  transcript
) => {
  const baseURL = config.API_URL;
  const path = "/realtime/transcript";
  console.trace()
  const body = {
    therapist_id: therapistId,
    therapy_id: therapyId,
    real_time_session_id: realtimeId,
    transcript: transcript,
  };

  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.post(`${baseURL + path}`, body, {
    headers: {
      Authorization: idToken,
    },
  });
  return data;
};

export const getRealtimeAudioUploadURLAPI = async (therapy_id, realtimeId) => {
  const baseURL = config.API_URL;
  const path = `/realtime/audio?therapy_id=${therapy_id}&real_time_session_id=${realtimeId}`;

  const body = {
    therapy_id: therapy_id,
    real_time_session_id: realtimeId,
  };

  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.put(`${baseURL + path}`, body, {
    headers: {
      Authorization: idToken,
    },
  });
  return data;
};

export const saveRealtimeAudioAPI = async (
  realtimeSessionId,
  bucket_name,
  bucket_key
) => {
  const baseURL = config.API_URL;
  const path = "/realtime/audio";

  const body = {
    real_time_session_id: realtimeSessionId,
    audio_bucket_name: bucket_name,
    audio_bucket_key: bucket_key,
  };

  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.post(`${baseURL + path}`, body, {
    headers: {
      Authorization: idToken,
    },
  });
  return data;
};

export const getTranscriptUrl = async (therapistId, realtimeId, therapyId) => {
  const baseURL = config.API_URL;
  const path = "/realtime/transcript";

  const fileKey = `realtime/${therapyId}/${realtimeId}/transcript.txt`;
  const params = "?therapist_id=" + therapistId + "&key=" + fileKey;

  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });
  return data;
};

export const getRealtimeAudioUrlAPI = async (therapistId, audio_bucket_key) => {
  const baseURL = config.API_URL;
  const path = "/realtime/transcript";

  const params = "?therapist_id=" + therapistId + "&key=" + audio_bucket_key;

  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });
  return data;
};

export const updateDurationOfRealtimeSession = async (
  realtimeSessionId,
  startDateTime,
  endDateTime
) => {
  const baseURL = config.API_URL;
  const path = "/realtime/session_update";

  const body = {
    real_time_session_id: realtimeSessionId,
    start_datetime: startDateTime,
    end_datetime: endDateTime,
  };

  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.put(`${baseURL + path}`, body, {
    headers: {
      Authorization: idToken,
    },
  });
  return data;
};

export const fetchRealtimeHealthStatusAndTags = async (
  therapy_id,
  therapist_id
) => {
  const baseURL = config.API_URL;
  const path = `/realtime/latest/soap?therapy_id=${therapy_id}&therapist_id=${therapist_id}`;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.get(`${baseURL + path}`, {
    headers: {
      Authorization: idToken,
    },
  });
  return res.data;
};

export const fetchActionSuggestions = async (
  therapyId,
  therapistId,
) => {
  const baseURL = "https://klm47yqivb5fmshp7n4ibp7i4e0hlukg.lambda-url.us-west-2.on.aws/";

  const body = {
    therapist_id: therapistId,
    therapy_id: therapyId,
  };

  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.post(`${baseURL}`, body, {
    headers: {
      Authorization: idToken,
    },
  });
  return data;
};
