import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useSearchParams } from "react-router-dom";
import { getOverallWellbeingScore } from "../../../../api/therapy";
import { generateLabelsAndData } from "./OverallWellbeingHelper";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import "./OverallWellbeingMap.css";
import ASSETS from "../../../../assets/Assets";
import Popup from "reactjs-popup";
import { Button, IconButton } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale
);

const WELL_BEING = {
  1: ASSETS.sunnyImage,
  0.5: ASSETS.partlyCloudyImage,
  0: ASSETS.cloudyImage,
};

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  layout: {
    padding: {
      left: 30,
      top: 12,
    },
  },
  scales: {
    x: {
      offset: true,
      grid: {
        display: false,
      },
    },
    y: {
      max: 1,
      min: 0,
      ticks: {
        display: false,
        stepSize: 0.5,
        beginAtZero: true,
      },
      grid: {
        display: false,
      },
    },
  },
};

function OverallWellbeingMap() {
  const [wellbeingStatusDataSet, setWellbeingStatusDataset] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const therapy_id = searchParams.get("therapy_id");
  
  useEffect(() => {
    getOverallWellbeingScore(therapy_id).then((data) => {
      const dataSet = generateLabelsAndData(data);
      
      const formatDate = (dateStr) => {
        const parts = dateStr.split(' ');
        const day = parseInt(parts[0]);
        const month = parts[1].split(',')[0];
        const year = `20${parts[1].split(',')[1]}`;
        const monthNumber = new Date(Date.parse(`${month} 1, ${year}`)).getMonth() + 1;
        return `${year}-${monthNumber < 10 ? '0' : ''}${monthNumber}-${day < 10 ? '0' : ''}${day}`;
      };
  
      dataSet.sort(function(a,b){
        return new Date(formatDate(a.x)) - new Date(formatDate(b.x));
      });
      setWellbeingStatusDataset(dataSet);
    });
  }, []);

  console.log("wellbeingStatusDataSet", wellbeingStatusDataSet)
  const data = {
    datasets: [
      {
        data: wellbeingStatusDataSet,
        borderColor: "#9090B5",
        backgroundColor: "#9090B5",
      },
    ],
  };

  return (
    <div className="overall-wellbeing-report-wrapper">
      <div className="overall-wellbeing-heading">
        Overall Wellbeing Score
        <Popup
          trigger={(open) => (
            <IconButton>
              <ErrorOutlineIcon />
            </IconButton>
          )}
          position="bottom center"
          closeOnDocumentClick
          on={["hover", "focus"]}
        >
          <div className="popup-body">
            <div className="popup-body-content">
              This is a visual representation of the overall well-being status
              of the client during each session. This map helps point out any
              sudden spikes in the well-being to compare the progress and look
              out for pink clouding in a clients emotions.
            </div>
            <div className="popup-images-list">
              <div className="popup-image-labels">
                <img src={ASSETS.sunnyGIF} alt="labels" />
                <span>Good (No Concerns)</span>
              </div>
              <div className="popup-image-labels">
                <img src={ASSETS.partlyCloudyGIF} alt="labels" />
                <span>Not so Good (Some Concerns)</span>
              </div>
              <div className="popup-image-labels">
                <img src={ASSETS.cloudyGIF} alt="labels" />
                <span>Bad (Concerns to Address)</span>
              </div>
            </div>
          </div>
        </Popup>
      </div>
      <div style={{ display: "flex", maxHeight: "16rem" }}>
        <div style={{ flex: "1 1 auto" }}>
          <Line
            options={options}
            data={data}
            plugins={[
              {
                afterDraw: (chart) => {
                  // console.log(chart);
                  var ctx = chart.ctx;
                  var xAxis = chart.scales["x"];
                  var yAxis = chart.scales["y"];
                  yAxis.ticks.forEach((value, index) => {
                    // console.log(value.value);
                    var y = yAxis.getPixelForTick(index);
                    var image = new Image();
                    image.src = WELL_BEING[value.value];
                    ctx.drawImage(image, 6, y - 12);
                  });
                },
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default OverallWellbeingMap;
